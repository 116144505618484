

.portfolio-h3 {
  font-size: 1.5rem;
}

.portfolio-image {
  width: 200px;
  display: block;
}

